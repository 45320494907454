import React, { useRef, useCallback, useEffect, useState } from 'react';
import styles from '../styles/TestOverview.module.css';
import html2canvas from 'html2canvas';
import OutsideAlerter from "./OutsideAlerter";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';

import { PDFDownloadLink } from '@react-pdf/renderer';
import Report from './Report';

import {
  useNavigate
} from 'react-router-dom';

const reportData = [];

for (let i = 0; i < 3; i++) {
  reportData.push({
    ["name" + i]: "test" + i
  });
}

const colors = [
  "#F8B195", // light salmon
  "#F67280", // coral pink
  "#C06C84", // dusty rose
  "#6C5B7B", // slate gray
  "#355C7D", // dark teal
  "#99B898", // sage green
  "#FECEAB", // apricot
  "#FF847C", // salmon
  "#E84A5F", // raspberry red
  "#2A363B", // charcoal gray
  "#FFA07A", // light coral
  "#FF6B6B", // tomato red
  "#E84A5F", // ruby red
  "#6D4C41", // sienna brown
  "#E5989B", // pinkish tan
  "#F8D5A3", // sandy beige
  "#F9C7CC", // light pink
  "#E1CDA8", // khaki green
  "#E29C45", // dark peach
  "#8E8D8A", // cool gray
  "#F5B041", // tangerine
  "#EB984E", // carrot orange
  "#2E4057", // navy blue
  "#283747", // dark slate gray
  "#212F3D"  // midnight blue
];

const TestOverview = ({ testID, title, worker, setTestInstanceInfo, study }) => {



function Test(worker) {
  // Notice that this function does not have a return. The return "messes up" the promises.
  // Make the MySQL query 
   fetch(`/get_battery_tasklist/${worker.name.id}`) 
   // convert response to JSON
  .then((response) => { return response.json() })
  // Work on the JSON version, which is an array containing a list
  .then((response) => {
      // get the only element in the array
      var ExpectedList = response[0].List
      // how long is the expected list
      var NExpected = ExpectedList.length
      // Are these tasks in the list of completed tasks?
      // find first list item
      var index = -99
      var count = 0
      // cycle over all tasks that have been completed
      history.map((h) => 
      // Find the first instance of Batteries. 
        {  if (( h.title != 'Batteries') & ( index < 0 )) {
          index = count
        }
        count = count + 1
        }
      )
      var NCompleted = 0
      // initialize the total score
      var BatteryTotalScore = 0.0
      // Cycle over the expected list and see how many have been completed
      ExpectedList.map((item) => { 
        // console.log(item, history[index].title)
        const scores = history[index]
        var tempData = JSON.parse(scores.data)
        var tempResults = tempData[tempData.length - 1].AllResults
        // see if completed tasks have a value that is non-negative
        if ( Number(tempResults.Accuracy) > -1 )
        { 
          // Keep track of how many tasks have been completed
          NCompleted = NCompleted + 1 
          // console.log(item +', '+tempResults.Accuracy, ', Total = '+BatteryTotalScore)
          // Calculate a battery total score
          BatteryTotalScore = BatteryTotalScore+Number(tempResults.Accuracy)
        }
        index = index + 1
      })
      // if there is an equal number of expected tasks as completed/scored tasks then the battery is complete
      if ( NCompleted == NExpected )
      {
        if ( document.getElementById("BatteryScore") )      
        // update the displayed total score
        // the total score should be sent back to the MySQL dB also
        { document.getElementById("BatteryScore").innerHTML = BatteryTotalScore } 
      }
      else { console.log("All Elements not scored ")}
    })
}


function GetBatteryName(worker) {
  const input = worker.name
  fetch(`/get_battery_name/${input?.id}`) 
    .then((response) => response.json())
    .then((data) => {
      // cycle over the data and find the first instance of Batteries
      //console.log(data)
      var FoundBatteryNameFlag = false

      data.map((item) => {
        if ( item.title == 'Batteries') 
        {
          if ( !FoundBatteryNameFlag ) 
          {
            //console.log("DISPLAYING EACH ITEM")
            //console.log(item.title)
            var temp = JSON.parse(item.data)
            //console.log(temp[0].BatteryName)
            //console.log(temp[0].BatteryScore)
            
            if ( document.getElementById("BatteryName") )
              { document.getElementById("BatteryName").innerHTML = temp[0].BatteryName }
            if ( document.getElementById("BatteryScore") )
              { document.getElementById("BatteryScore").innerHTML = temp[0].BatteryScore }
            FoundBatteryNameFlag = true
          }
        }
      })
      /*var temp = JSON.parse(data[0].data)
      console.log(temp[0])
      document.getElementById("BatteryName").innerHTML = temp[0].BatteryName;
      document.getElementById("BatteryScore").innerHTML = temp[0].BatteryScore;
      */
    })
  }


// Get the expected list of tasks to complete
function GetExpectedList(ID){
  return fetch(`/get_battery_tasklist/${ID.name.id}`) 
  .then((response) => { return response.json() })
  .then((data) => {
    data.map((item) => {
      document.getElementById("BatteryScore").innerHTML = item
    })})}
    
  
/*
  
    var ExpectedList = data[0].List
    var NExpected = ExpectedList.length
    // Are these tasks in the list of completed tasks?
    // find first list item
    var index = -99
    var count = 0
    history.map((h) => 
      {  if (( h.title != 'Batteries') & ( index < 0 )) {
        index = count
      }
      count = count + 1
      }
    )
    var NCompleted = 0
    var BatteryTotalScore = 0.0
    ExpectedList.map((item) => { 
        //console.log(item, history[index].title)
        // get history item score
        const scores = history[index]
        //console.log(scores)
        var tempData = JSON.parse(scores.data)
        var tempResults = tempData[tempData.length - 1].AllResults
        if ( Number(tempResults.Accuracy) > -1 )
        { 
          NCompleted = NCompleted + 1 
      //    console.log(item +', '+tempResults.Accuracy, ', Total = '+BatteryTotalScore)
          BatteryTotalScore = BatteryTotalScore+Number(tempResults.Accuracy)
        }
        index = index + 1
      }
    )
    // Check if data is complete and scored
    if ( NCompleted == NExpected )
    {
      if ( document.getElementById("BatteryScore") )      
        { document.getElementById("BatteryScore").innerHTML = BatteryTotalScore } 
    }
    //console.log("Expected Count: "+NExpected)
    //console.log("Completed Count: "+NCompleted)
    //return data[0].List
    
  })
}
*/
/*
function Check if all tasks in the battery have scores
List all tasks in a battery
*/
  const saveChartAsImage = async () => {
    const chartNode = invisibleGraphContainer.current;

    const options = {
      useCORS: true,
      scale: 2, // increase the scale to improve the quality
      backgroundColor: null, // set the background color to transparent
      logging: false, // disable logging to improve performance
    };

    const canvas = await html2canvas(chartNode, options);
    const base64Url = canvas.toDataURL('image/png');
    setChartImageUrl(base64Url);
  };



  
  const [batch, setBatch] = useState()
  const [chartImageUrl, setChartImageUrl] = useState(null);

  const [results, setResults] = useState([]);
  const [noDataPDFPopup, setNoDataPDFPopup] = useState(false);
  const [history, setHistory] = useState([]);
  const [components, setComponents] = useState(testID === -1 ? [] : [testID]);
  const [notesPopup, setNotesPopup] = useState(false);

  useEffect(() => {
    if (results.length) {
      setTimeout(() => saveChartAsImage(), 50);
    }
  }, [results]);

  useEffect(() => {
    
    if (testID === -1) {
    

      fetch(`/get_user_history/${worker?.id}/${study?.uuid}`)
        .then((response) => { return response.json() })
        .then((history) => {
          // history is the json formatted return from mySQL
          //console.log(history)
          // somehow after this point some of the components are lost
          // the field: id is the componentResults id
          // the problem is in the next line. The history is mapped based on
          // on teh conmponet id, which is not unique!!!
          // I changed h.componet_id to be h.id
          const components = [...new Set(history.map(h => h.id))];
          //console.log("+++++++++++++COMPONENTS ==========")
          //console.log(components)

          setComponents(components);
          setHistory(history);

          if (components?.length !== 0 && history?.length !== 0) {
            const data = [];
            for (let i = 0; i < components.length; i++) {
              // id is now the component result id
              const id = components[i];
              // I changed h.component_id to be h.id
              const scores = history.filter(h => h.id === id);
              //console.log("SCORE: ")
              //console.log(scores)
              for (let j = 0; j < scores.length; j++) {
                const score = scores[j];
                const fullData = JSON.parse(score.data);
                
                
                const resultsData = fullData[fullData.length - 1].AllResults;
                var resultsAllData = fullData[fullData.length - 1].AllResults;
                // Search through full data from jspsych
                //console.log("=============== RESULTS ++++++++")
                //console.log(resultsAllData)
                // See if there is an AUDIO RESPONSE for this data
                // If there is then add it to the data for display
                var needAudio = true
                for ( var ii = 0; ii < fullData.length; ii++ ) {
                  if (fullData[ii].hasOwnProperty("AudioResponse")) {
                    console.log("FOUND AUDIO IN: "+ii)

                    console.log(resultsData)

                    if ( needAudio ) 
                      { 

                        resultsAllData.Audio = [] 
                        needAudio = false
                      }
                    console.log("FOUND AUDIO IN: "+ii)
                    //console.log(fullData[ii].AudioResponse)
                    resultsAllData.Audio.push(fullData[ii].AudioResponse)
                   }
                }
                // See if there is an PNG for this data
                // If there is then add it to the data for display
                for ( var ii = 0; ii < fullData.length; ii++ ) {
                  if (fullData[ii].hasOwnProperty("png")) {
                    console.log("FOUND PNG IN: "+ii)
                    resultsAllData.Image = fullData[ii].png
                   }
                }
                // See if there is an GIF for this data
                // If there is then add it to the data for display
                for ( var ii = 0; ii < fullData.length; ii++ ) {
                  if (fullData[ii].hasOwnProperty("gif")) {
                    console.log("FOUND GIF IN: "+ii)
                    resultsAllData.GIF = fullData[ii].gif
                   }
                }
                // See if there is mouse tracking for this data
                // If there is then add it to the data for display
                for ( var ii = 0; ii < fullData.length; ii++ ) {
                  if (fullData[ii].hasOwnProperty("mouse_tracking_data")) {
                    console.log("FOUND Mouse tracking data IN: "+ii)
                    resultsAllData.MouseTracking = fullData[ii].mouse_tracking_data
                   }
                }

                console.log(resultsAllData)
                if (resultsData) {
                    const dataPoint = {
                    component_id: id,

                    // Time as a number
                    Time: new Date(score.endDate).getTime(),

                    // Time as a string for display
                    ShortTime: timeString(score.endDate),
                    FullTime: fullTimeString(score.endDate),

                    // Component ID based score for creating graph
                    [id]: resultsData.Accuracy ? resultsData.Accuracy : 'N/A',

                    // General access score for the data point
                    Score: resultsData.Accuracy, //? resultsData.Accuracy : 'N/A',
                    ScoreName: resultsData.ScoreName,
                    // The results data
                    Data: resultsData,
                    AllData: resultsAllData,


                    title: score.title,
                    endDate: fullTimeString(score.endDate),
                    startDate: fullTimeString(score.startDate),
                  };
                  data.push(dataPoint);
                }
              }
            }
            setResults(data);
          }
        });
    } else {      
      fetch(`/get_test_results/${testID}/${worker.id}`)
        .then((response) => response.json())
        .then((data) => {
          console.log("===== TEST RESULTS =========")
          const res = data.map((e) => {
            const fullData = JSON.parse(e.data);
            console.log(fullData)
            const resultsData = fullData[fullData.length - 1].PrimaryResults;
            const resultsAllData = fullData[fullData.length - 1].AllResults;
            if (data) {
              return {
                component_id: testID,

                // Time as a number
                Time: new Date(e.startDate).getTime(),

                // Time as a string for display
                ShortTime: timeString(e.startDate),
                FullTime: fullTimeString(e.startDate),

                // Component ID based score for creating graph
                [testID]: resultsData.Accuracy,

                // General access score for the data point
                Score: resultsData.Accuracy,
                ScoreName: resultsData.ScoreName,

                // The results data
                Data: resultsAllData,

                title: title,
                
                endDate: fullTimeString(e.startDate),
                startDate: fullTimeString(e.startDate),
              };
            } else {
              return {
                id: e.id
              }
            }
          });
          setResults(res);
          setComponents([testID]);
          setHistory([]);
        });
    }
  }, [title, worker?.id, study?.uuid, testID]);

  
  
  const timeString = (date) => {
    return (new Date(date).toLocaleDateString("en-CA", {
      dateStyle: "medium"
    }));
  }


  const fullTimeString = (date) => {
    return (new Date(date).toLocaleString("en-CA", {
      dateStyle: "medium",
      timeStyle: "short"
    }));
  }

  const graphContainer = useRef(null);
  // This is used to save the graph as an image
  const invisibleGraphContainer = useRef(null);

  const navigate = useNavigate();
  const nav = useCallback((path) => {
    navigate(path, { replace: true });
  }, [navigate]);

  const RefLegend = (title, i) => {
    return (
      <div key={i}>
        <span style={{ color: colors[i], opacity: 0.75 }}>
          ⬤
        </span>
        {title}
      </div>
    );
  }

  const CustomTooltip = ({ active, payload }) => {
    if (!payload) return <></>;
    const data = payload[0]?.payload;
    if (data) {
      return (
        <div className={styles.customTooltip} style={{ width: "260px" }}>
          <div><b>Date:</b> {fullTimeString(data?.Time)}</div>
          <div><b>Score:</b> {data?.Score}</div>
        </div>
      );
    }
  };

  const formatXAxisTick = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString();
  };

  const Graph = () => {
    return (
      <>
        {
          ((results && results.length) ? (
            <div style={{ width: '100%', height: '100%', position: 'relative' }}>
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  top: 0,
                  left: 0
                }}
              >
                <div className={styles.refLegend}>
                  {components.map((c, i) => {
                    const title = results.filter(h => h.component_id === c)[0]?.title;
                    if (title && history.length) {
                      return RefLegend(title, i);
                    } else {
                      return <></>;
                    }
                  })}
                </div>
                <ResponsiveContainer width="99%" height="99%">
                  <LineChart
                    width={500}
                    height={300}
                    data={results.sort((a, b) => b.Time - a.Time)}
                    margin={{
                      left: -23,
                      top: 15,
                      bottom: 5
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      tickFormatter={formatXAxisTick}
                      dataKey="Time"
                      type="number"
                      scale="time"
                      domain={['auto', 'auto']}
                    />
                    <YAxis
                      type="number"
                      domain={['0', '100']}
                    />
                    <Tooltip
                      content={<CustomTooltip />}
                      position={{ x: 60, y: 40 }}
                    />
                    {components.map((c, i) => {
                      return (
                        <Line
                          connectNulls
                          key={i}
                          type="monotoneX"
                          dataKey={c}
                          stroke={colors[i]}
                          strokeWidth={3}
                          dot={{ r: 4 }}
                          activeDot={{ r: 8 }}
                          isAnimationActive={false}
                        />
                      );
                    })}
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          ) : (
            <div className={styles.noScores}>
              <p>No data.</p>
              <p>This worker has not completed any tests yet.</p>
              <button onClick={() => {
                fetch(`/send_test_email/${worker.email}/${worker.studyCode}`)
                  .then(() => {
                    alert('Test link sent succesfully.');
                  });
              }}><div className={styles.sendIcon} />Send Test Link</button>
            </div>
          ))
        }
      </>
    );
  }

  const [notePrompt, setNotePrompt] = useState(false);
  const [noteContent, setNoteContent] = useState("");




  const handleChangeNote = (event) => {
    setNoteContent(event.target.value);
  }

  return (
    <>
      <div
        ref={invisibleGraphContainer}
        className={styles.hiddenGraph}
      >
        {Graph()}
      </div>
      {notesPopup && (
        <div className={styles.modalBackground}>
          <div className={styles.modal}>
            <div className={styles.header}>Report Notes</div>
            <textarea
              value={noteContent}
              onChange={handleChangeNote}
              className={styles.notes} />
            <div className={styles.footer}>
              <PDFDownloadLink
                onClick={() => {
                  setNoteContent("");
                  setTimeout(() => {
                    setNotesPopup(false);
                    setNotePrompt(false);
                  }, 0);
                }}
                document={<Report
                  graphImgUrl={chartImageUrl}
                  results={results}
                  notes={noteContent}
                  studyCode={worker.studyCode}
                  email={worker.email}
                />}
                fileName={`${title} - Report.pdf`}>
                {({ blob, url, loading, error }) => {
                  return (
                    <>
                    </>
                  );
                }}
              </PDFDownloadLink>
              <button>OK</button>
            </div>
          </div>
        </div>
      )}
      {noDataPDFPopup && (
        <div className={styles.modalBackground}>
          <div className={styles.modal}>
            <div className={styles.header}>No Report Available</div>
            <div className={styles.description}>
              <p>There is currently no data for this test. </p>
              <p>You can generate a report after taking the test at least once.</p>
            </div>
            <div className={styles.footer}>
              <button onClick={() => setNoDataPDFPopup(false)}>OK</button>
            </div>
          </div>
        </div>
      )}
      <div className={styles.container}>
        <div className={styles.topSection}>
          <div className={styles.header}>{title}</div>
          {title === "Worker Overview" && (
            <div className={styles.userInfo}>
              {worker.studyCode ? <div>
                <b>Link Code: </b>
                {worker.studyCode}</div> :
                <></>}
              {worker.studyCode ? <div>
                <b>Email: </b>
                {worker.email}</div> :
                <></>}
            </div>
          )}
          <div className={styles.mainButtons}>
            <button onClick={() => {
              fetch(`/send_test_email/${worker.email}/${worker.studyCode}`)
                .then(() => {
                  alert('Test link sent succesfully.');
                });
            }}><div className={styles.sendIcon} />Send Test Link</button>
            {(results.length && chartImageUrl) ? (
              <>
                {!notePrompt && (
                  <div key={Date.now()} className={"btn"}>
                    <div onClick={() => {
                      setNotePrompt(true);
                    }}>
                      <div
                        key={Date.now()}
                        className={`${styles.sendIcon} ${styles.pdfIcon}`}
                      />
                      Download Report
                    </div>
                  </div>
                )}
                {notePrompt && (
                  <OutsideAlerter setNotePrompt={setNotePrompt}>
                    <span className={styles.notePrompt}>Add Note?</span>
                    <div onClick={() => {
                      setNotesPopup(true);
                    }} className={`${styles.noteBtn} btn`}>
                      Yes
                    </div>
                    <div className={`${styles.noteBtn} btn`}>
                      <PDFDownloadLink
                        onClick={() => {
                          setTimeout(() => setNotePrompt(false), 0);
                        }}
                        document={<Report
                          title={title}
                          graphImgUrl={chartImageUrl}
                          results={results}
                          notes={noteContent}
                          studyCode={worker.studyCode}
                          email={worker.email}
                        />}
                        fileName={`${title} - Report.pdf`}>
                        {({ blob, url, loading, error }) => {
                          return (
                            <>
                              {loading ? 'No' :
                                <>
                                  No
                                </>}
                            </>
                          );
                        }}
                      </PDFDownloadLink>
                    </div>
                  </OutsideAlerter>
                )}
              </>
            ) : (
              <div key={Date.now()} className={"btn"} onClick={() => setNoDataPDFPopup(true)}>
                Report Unavailable
              </div>
            )}
          </div>
        </div>
        
        <div className={styles.bottomSection}>
        {
          /*<div className={styles.graph}>
            <div className={styles.subHeader}>
              Scores Over Time
            </div>
            <div ref={graphContainer} className={styles.graphContainer}>
              {Graph()}
            </div>
          </div>*/
          }
          <div className={`${styles.history} ${history?.length ? styles.overviewHistory : ''}`}>
            <div className={styles.subHeader} style={{
              padding: "18px",
              paddingLeft: "10px",
              paddingBottom: "0em"
            }}>
              History
            </div>
            {(results.length || history.length) ? (
              <>
                <div className={`${styles.historyRow} ${styles.dateScore}`}>
                  {history.length !== 0 && (
                    <div className={styles.historyHeader}>
                      <div>Test Name</div>
                      <div>Start Time</div>
                      <div>Score Name</div>
                      <div>Score</div>
                    </div>
                  )}
                  
                  {history.length === 0 && (
                    <>
                      <div className={styles.left}>Date</div>
                      <div className={styles.right}>Score</div>
                    </>
                  )}
                  
                </div>
                
                <div className={styles.historyScroll}>
                  {history.length !== 0 && (results.sort((a, b) => a.Time - b.Time).map((e, i) => {
                    return <div
                      key={i}
                      className={`${styles.historyRow} ${styles.overviewRow}`}
                      onClick={() => {
                        console.log(e)
                        setTestInstanceInfo({
                          title: e.title,
                          Score: e.Score,
                          startDate: e.startDate,
                          endDate: e.endDate,
                          Data: e.AllData,
                          alle: e.component_id,
                          history: history,
                          // JASON's addition
                          componentResultsId: history[0].id,
                        });
                        nav("/history");
                      }}>
                      <div><b>{e.title}</b></div>
                      <div style={{ fontSize: "0.95em", width: "125px" }}>
                        {e.startDate}
                      </div>
                      <div b style={{ fontSize: "0.95em", width: "125px" }}>
                        {e.Data.ScoreName}
                      </div>
                      <div><b style={{ fontSize: "1.2em" }}>
                        {(Math.round(e.Data.Accuracy*1000)/1000)}
                        
                      </b></div>
                      
                    </div>
                    
                  }))}

                  {(history.length === 0 && results.length !== 0) &&
                    (results.map((e, i) => {
                      return (
                        <div
                          key={i}
                          className={styles.historyRow}
                          onClick={() => {
                            setTestInstanceInfo({
                              ...e,
                              title: title
                            });
                            nav("/history");
                          }}>
                          <div className={styles.left}>{e.FullTime}</div>
                          <div className={styles.right}>{e.Score}</div>
                        </div>
                      );
                    }))}
                </div>
              <div>
                <p>Battery Name: <b id='BatteryName'></b></p>
                <p>Battery Score: <b id='BatteryScore'></b></p>
                <GetBatteryName name={worker} />
                
                
                
                
                

                
                </div>
              </>
            ) : <>
              <div className={styles.noScores}>
                <p>No data.</p>
              </div>
            </>}
          </div>
        </div>
      </div>
    </>
  );
}
export default TestOverview;