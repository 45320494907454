import React, { useState, useEffect } from 'react';

import styles from '../styles/Home.module.css';

const Home = ({ study, setStudy, username, batchname }) => {

  const [studies, setStudies] = useState([]);

  useEffect(() => {
    //fetch(`/get_studies/${username}`)
    fetch(`/get_studies`)
      .then((response) => response.json())
      .then((data) => {
        setStudies(data);
      });
  }, [username]);

  return (
    <div className={styles.container}>
      <div>
        <h1>
          Welcome to the <br /> NCM Lab Study Manager.
        </h1>

        <div className={styles.studyManagerLbl}>
          Please pick a study to manage:
        </div>
        <select onChange={(e) => {
          setStudies(studies.filter((s) => s.uuid === e.target.value)[0]);
        }} value={study.uuid}>
          {studies.map((s, i) => {
            return <option
              key={i}
              value={s.uuid}>
              {s.title}
            </option>
          })}
        </select>

        <h2>Links</h2>
        <div>
          JATOS:
        </div>
        <a rel="noreferrer"
          target="_blank" href="https://jatos.ncmlab.ca/jatos/login">
          https://jatos.ncmlab.ca
        </a>
        <div>
          NCM Lab Study Manager Wiki:
        </div>
        <a rel="noreferrer"
          target="_blank" href="https://github.com/chrisvenczel/NeuroClinic/wiki">
          https://github.com/chrisvenczel/NeuroClinic/wiki
        </a>
        <div>
          NCM Lab GitHub:
        </div>
        <a rel="noreferrer"
          target="_blank" href="https://github.com/NCMlab/NCMLabOnlineTools">
          https://github.com/NCMlab/NCMLabOnlineTools
        </a>
      </div>
    </div>
  );
}

export default Home;