import React from 'react';
import styles from '../styles/TestOverview.module.css';
import {
  FindTestToUpdate,
  UpDateWordRecall,
  AddNotes, 
  closeForm
 } from "./UpdateTestFunctions.js"

 import "./TestFunction.js"

/* 
<h3>Replay Drawing</h3>
            <div style={{position: 'relative'}}>
              <img id="ReplayImage" src={value} alt="Sketchpad screenshot"></img>
               showPosition('ReplayImage')  
            </div>
            <div style={{position: 'absolute', top: '100'}}>JASXXON</div>
*/

// https://www.robinwieruch.de/react-button/

function addSpaceBetweenListItems(p1) {
  return " "+p1;
}
// Open the form for editing the word recall table


// NEED TO READ THIS (6/14/24) https://www.quirksmode.org/js/findpos.html
//<div style={{position: 'absolute', left: findPos(document.getElementById('ReplayImage'))[0],  top: findPos(document.getElementById('ReplayImage'))[1]}}>X</div>

/*          <h3>Replay Drawing</h3>
            <div style={{position: 'relative'}}>
              <img id="ReplayImage" src={value} alt="Sketchpad screenshot"></img>
            </div>
*/


function showPosition(id) {
  var element = document.getElementById(id)
  const rect = element.getBoundingClientRect();
  console.log("X: " + rect.x, "Y: "+(rect.y+window.scrollY))
  
}
function findPos(obj) {
  var curleft = 0;
  var curtop = 0;
  if ( obj.offsetParent ) {
    do {
        curleft += obj.offsetLeft;
        curtop += obj.offsetTop;
    }
    while ( obj = obj.offsetParent );
    }
  return [curleft, curtop]
}
function AddMouseTracking() {
  console.log("MOUSE TRACK BUTTON PRESSED")
  console.log(findPos(document.getElementById('ReplayImage')))
  return <div style={{position: 'absolute', left: findPos(document.getElementById('ReplayImage'))[0],  top: findPos(document.getElementById('ReplayImage'))[1]}}>X</div>
  //return null
}

function showPositionY(id) {
  var element = document.getElementById(id)
  const rect = element.getBoundingClientRect();
   return (rect.y+window.scrollY)
}

function showParameters(testData) {
  console.log(testData)
    // Get the modal
  var modal = document.getElementById("parametersModal");
  var parameterInfo = document.getElementById("parameterInformation");
  // FETCH PARAMETERS FROM dB and UPDATE here
  console.log(testData)
  const component = testData.history.find(o => o.id === testData.alle)
  console.log(component)
  const ID = component.id
  console.log(ID)
  // update the word recall table in JATOS 
  fetch(`/fetch_Parameters/${component.id}`)
    .then((response) => { 
      return response.json() 
    }).then((data) => {
      var TableOfParameters = `<table border = 1>`
      TableOfParameters += `<tr><td><b>Parameter</b></td><td><b>Value</b></td></tr>`
      console.log(data[0].d['NBlocks'])
      console.log(Object.keys(data[0].d).forEach(function (key) 
      {
        TableOfParameters += `<tr><td>`
        TableOfParameters += key
        TableOfParameters += `</td><td>`
        TableOfParameters += data[0].d[key]
        TableOfParameters += `</td></tr>`
      }
      //TableOfParameters += `</table>`
      ))
      parameterInfo.innerHTML = JSON.stringify(data[0].d)
      parameterInfo.innerHTML = TableOfParameters
    })
  
  // Get the <span> element that closes the modal
  var span = document.getElementsByClassName("close")[0];
    modal.style.display = "block";
    // When the user clicks on <span> (x), close the modal
    span.onclick = function() {
      modal.style.display = "none";
    }

    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function(event) {
      if (event.target == modal) {
        modal.style.display = "none";
      }
    }
}


// make function to add the edited information back to the displayed original table


function renderSwitch(key, value, data) {
 const _foo = value[0];
 <div>return ({key})</div>
  //https://stackoverflow.com/questions/46592833/how-to-use-switch-statement-inside-a-react-component
  switch(key) {
    case 'Point by Point Data':
      console.log("### POINT #### POINT ####")
      return (
        <div>
        <p></p>
          <div><h3>POINT BY POINT DATA</h3></div>
          <div>
          <table border='1px'>
            <thead>
              <td><b>Count</b></td>
              <td><b>Label</b></td>
              <td><b>Distance (pix)</b></td>
              <td><b>Speed (pix/ms)</b></td>
              <td><b>Dwell Time (ms)</b></td>
            </thead>
            <tbody>
            {value.map((item,index) => {
                return (
                  <tr>
                    <td align="right">{item.Count}</td>
                    <td align="right">{item.Label}</td>
                    <td align="right">{item.Distance ? item.Distance.toFixed(2) : '-99'}</td>
                    <td align="right">{item.speed ? item.speed.toFixed(2): '-99'}</td>
                    <td align="right">{item.DwellTime ? item.DwellTime.toFixed(2) : '-99'}</td>
                  </tr>
                )
                })}
            </tbody>
          </table>
        </div>
          
        </div>
      )
    case 'MouseTracking':
      return (
        <div>Found mouse tracking data
         
        </div>
        )
    case 'Image':
      return (
        <div> 
        <h3>Final Drawing</h3>
          <img src={value} alt="Sketchpad screenshot"></img>
            
        </div>
      )
    case 'GIF':
      return (
        <div style={{position: 'relative'}}> 
        <h3>GIF of Drawing</h3>
          <img src={value} alt="Sketchpad GIF"></img>
        </div>
      )
      case 'Audio':
        return (
          <div> 
          <h3>Audio recording</h3>
            <div>
            {value.map((i,ind) => {return (
              <div>
                <h4>Block {ind+1}</h4>
                <audio controls="controls" autobuffer="autobuffer">
                  <source src={"data:audio/mpeg;base64,"+value[ind]}/>
                </audio>
              </div>)})}   
            </div>
          </div>
        )
        
    case 'DS Response Array':
      return ( 
        <div>
          <table border='1px'>
            <thead>
              <td>Trial</td>
              <td>Load</td>
              <td>List</td>
              <td>Response</td>
              <td>Accuracy</td>
            </thead>
            <tbody>
            {value.map((item,index) => {
                return (
                  <tr>
                    {item.map((i,ind) => {return (<td>{Array.isArray(i) ? addSpaceBetweenListItems(i) : i}</td>)})}  
                  </tr>
                )
                })}
            </tbody>
          </table>
        </div>
        )

    case 'Response Array A':
      return (
        <div>
          <h3>Word List</h3>
          <table id="WordListResponseTable">
          <thead>
            <td>Word</td>
              {_foo.map((i,ind) => {return (<td>Block {ind+1}</td>)})}   
          </thead>
          <tbody >
              {value.map((item,index) => {
                return (
                  <tr>
                    <td>{data.WordListA[index]}</td>
                    {item.map((i,ind) => {return (<td>{i < 0 ? ' ' : i+1}</td>)})}  
                  </tr>
                )
                })}
            </tbody>
            
          </table>
        </div>
      )
    case 'Response Array B':
      return (
        <div>
          <h3>Word List B</h3>
          <table>
          <thead>
            <td>Word</td>
              {_foo.map((i,ind) => {return (<td>Block {ind+1}</td>)})}   
          </thead>
          <tbody >
              {value.map((item,index) => {
                return (
                  <tr>
                    <td>{data.WordListB[index]}</td>
                    {item.map((i,ind) => {return (<td>{i < 0 ? ' ' : i}</td>)})}  
                  </tr>
                )
                })}
            </tbody>
            
          </table>
        </div>
      )
    case 'Intrusions A':
      return (
        <div>
        <h3>Intrusions During A</h3>
          <table border='1px'>
            <tbody>
              {value.map((item,index) => {
                return (
                  <tr>
                    <td>Block {index+1}</td>
                    <td>{addSpaceBetweenListItems(item)}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )
      case 'Intrusions B':
        return (
          <div>
          <h3>Intrusions During B</h3>
            <table border='1px'>
              <tbody>
                {value.map((item,index) => {
                  return (
                    <tr>
                      <td>Block {index+1}</td>
                      <td>{addSpaceBetweenListItems(item)}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )  

        case 'User Said A':
          return (
            <div>
            <h3>Speech Processing A</h3>
              <table border='1px'>
                <tbody>
                  {value.map((item,index) => {
                    return (
                      <tr>
                        <td>Blk {index+1}</td>
                        <td>{item}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          )
    case 'User Said B':
      return (
        <div>
        <h3>Speech Processing B</h3>
          <table border='1px'>
            <tbody>
              {value.map((item,index) => {
                return (
                  <tr>
                    <td>Blk {index+1}</td>
                    <td>{item}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )  
    case 'Speech Processing':
      return (
      <div><h4>Speech Processing:</h4>
      <p>[Note: The speech processing program makes of list of what it thinks it hears and chooses the most probable words. 
      The most probale words are shown in <em>Spoken Words.</em> The five other possible words are listed in <em>Speech Processing</em>]
      </p>
        {value.split(';').map(line => <li key="{line}">{line}</li>)}
      </div>)
    case 'Spoken Words':
      return (        
      <div><h4>Spoken Words:</h4>
        <div>{value.map((i, ind) => {return (i+', ' )})}</div>
      </div>)
   
    case 'WordListA':
      return (<div></div>)
    case 'WordListB':
      return (<div></div>)
    case 'ScoreName':
      return (<div></div>)
    case 'Accuracy':
      return (<div></div>)
    case 'CardSortColNames':
        return (<div></div>)
    case 'Card Sort Table':
      return (
      <div>
          <h3>Card Sort</h3>
          <table border='1px'>
          <thead>
            {data.CardSortColNames.map((item, index) => { return <td>{item}</td>})}
          </thead>
          <tbody >
              {value.map((item,index) => {
                return (
                  <tr>
                    
                    {item.map((i,ind) => {return (<td>{i}</td>)})}  
                  </tr>
                )
                })}
            </tbody>
            
          </table>
          {data.TableColNames}
        </div>
      
      
      
      )
    default:
      return (
        <div>
          <b>{key}:</b>
          {addSpaceBetweenListItems(value)}
        </div>
        )
  }
}

const TestInstance = ({ testData }) => {
  
  const JasonClick03 = () => {
    console.log("HELLO WORLD")
    if (testData.title === "Word Recall") {
      console.log(testData.title)
    }  
  }
  
  

  
  const UpdateWordRecall = (input) => {alert("Notice: "+ input)};
  console.log(testData)
  var inputX = "JASON"
  
  return (
    
    <div className={styles.container}>
    <div id="parametersModal" class="modal">
      <div class="modal-content">
        <span class="close">&times;</span>
        <div id="parameterInformation">No available parameters</div>
      </div>

    </div>


      <div className={styles.reviewContainer}>
        <div className={styles.header}>{testData?.title} Review</div>
        <div className={styles.review}>
          <div><b>Start Time: </b> {testData.startDate}</div>
          <div><b>End Time: </b> {testData.endDate}</div>
          {Object.entries(testData.Data).map(([key, value]) => (
            <div>
              
              {renderSwitch(key, value, testData.Data)}
            </div>
          ))}
        </div>
        
        <div>
            
          <button type="button" onClick={() => FindTestToUpdate(testData)}>Update Score</button>
          <button type="button" onClick={() => AddNotes(testData)}>Add Notes</button>
          <button type="button" class="btn" onClick={() => showParameters(testData)}>Show Parameters</button>
          
        </div>
        
        
        <div class="chat-popup" id="myForm">
          <form class="form-container">
            <div id="PopupContent">
            </div>
            <button type="button" id="SubmitButton" class="btn">Submit</button>

            <button type="button" class="btn cancel" onClick={closeForm}>Close</button>
          </form>
        </div>
      </div>
    </div>


  );
}
//<div><button type="button" onClick={JasonClick02(JSON.stringify(testData.componentResultsId))}>Update Score</button></div>



export default TestInstance;