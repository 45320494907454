import React, { useEffect, useState } from 'react';
import { useTable } from 'react-table';

import styles from '../styles/Links.module.css';


var BatchName = 'Default'

const Table = ({ columns, data }) => {

  
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  // Render the UI for your table
  return (
    <table {...getTableProps()} className={styles.userTable}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

function GetSelectedBatchName() {
  var BatchName
  BatchName = document.getElementById('SelectedBatch').innerHTML
  if ( BatchName === '' )
  {
    BatchName = "Default"
  }
  else {console.log("FALSE")
      console.log(BatchName)}
  return BatchName
}
//const BatchName = 'NCM_10-23-9755'


//console.log(document.getElementById('SelectedBatch').innerHTML)
//var BatchName = 'Default'

//BatchName = document.getElementById('SelectedBatch').innerHTML
//const BatchName = 'Default'
const Links = ({ setWorker, study}) => {

  const [users, setUsers] = useState(null);
  const [search, setSearch] = useState("");
  
  
  const [selectedBatch, setSelectedBatch] = useState();

  //fetch(`/get_links/${study.uuid}`)
  useEffect(() => {
    fetch(`/get_links/${GetSelectedBatchName()}`)
      .then((response) => response.json())
      .then((data) => {
        const res = [...data].map((e) => {
          return {
            
            code: e.studyCode,
            date: e.JASONDATE,
            batch: e.title,
            email: e.comment,
            worker:e.worker_id,
            actions: <div>
              <button onClick={() => {
                setWorker({
                  id: e.worker_id,
                  email: e.comment ? e.comment : e.id,
                  studyCode: e.studyCode
                });
              }}>View</button>
              {e.worker_id !== 1 && (<button onClick={() => {
                fetch(`/send_test_email/${e.comment}/${e.studyCode}`)
                  .then(() => {
                    alert('Test link sent succesfully.');
                  });
              }}>Send Link</button>)}
            </div>
          };
        });
        setUsers(res);
      });
  }, [setWorker, study.uuid]);
  

  
  const columns = React.useMemo(
    () => [
      {
        Header: 'Test',
        columns: [
          {
            Header: 'Batch',
            accessor: 'batch',
          },
          {
            Header: 'Test Date',
            accessor: 'date',
          },
          {
            Header: 'Worker',
            accessor: 'worker',
          },
          {
            Header: 'Actions',
            accessor: 'actions'
          }
        ],
      },
    ],
    []
  )


  if (users === null) return <div className={styles.container}
    style={{ fontSize: "1.5em" }}>
    Loading...
  </div>

  if (users.length === 0) return <div className={styles.container}
    style={{ fontSize: "1.5em" }}>
    No Study Links
  </div>





  return (
    <div className={styles.container}>
      <div>

        <div className={styles.activeLinksHeader}>
          <div className={styles.link}/>
          <h2>Study Links</h2>
          <div>
          JASON: 
          
          {selectedBatch}
            
          
          </div>
          <div className={styles.searchContainer}>
            <input value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              type="text" placeholder="Search..." />
          </div>

        </div>
        

        <Table columns={columns} data={users.filter((u) => {
          return u.batch.includes(search) ||
            u.code.includes(search) ||
            u.email.includes(search)
        })} />
      </div>
      
    </div>
  );
}

export default Links;